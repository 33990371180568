<template>
  <div class="hegfez-infor">
    <div class="infot">
      <div class="top-code">
        <img :src="require(`@/assets/images/bz.png`)" alt="" />
        <div class="code">
          <h1>合格证编号</h1>
          <h1>{{ data.certificateCode }}</h1>
        </div>
      </div>
      <div class="basis-con">
        <div class="title">
          <img :src="require(`@/assets/images/le.png`)" alt="" />
          <h2 class="tips-title">我承诺对生产销售的食用农产品</h2>
          <img :src="require(`@/assets/images/ri.png`)" alt="" />
        </div>
        <div class="tips-content">
          <van-checkbox-group v-model="data.commitmentContent">
            <van-checkbox
              v-for="item in contentList"
              :key="item.value"
              v-model="item.value"
              :name="item.value"
              disabled
              shape="square"
              >{{ item.label }}</van-checkbox
            >
          </van-checkbox-group>
        </div>
      </div>
      <div class="basis-con tips-list">
        <div class="title">
          <img :src="require(`@/assets/images/le.png`)" alt="" />
          <h2 class="tips-title">承诺依据</h2>
          <img :src="require(`@/assets/images/ri.png`)" alt="" />
        </div>
        <div class="tips-content">
          <van-checkbox-group
            class="van-checkbox-group-t"
            v-model="data.commitmentBasis"
          >
            <van-checkbox
              v-for="item in basisList"
              :key="item.value"
              v-model="item.checked"
              :name="item.value"
              disabled
              shape="square"
              >{{ item.label }}</van-checkbox
            >
          </van-checkbox-group>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="list">
      <div class="pannel">
        <span>产品名称：</span>
        <h1 style="color: #11cd61">{{ data.productName }}</h1>
      </div>
      <div class="pannel">
        <span>产品数量：</span>
        <h1>{{ data.number }}{{ data.unitData }}</h1>
      </div>
      <div class="pannel">
        <span>产品重量：</span>
        <h1>{{ data.unitWeight }}{{ data.weightUnit }}</h1>
      </div>
      <div class="pannel">
        <span>产品产地：</span>
        <h1>{{ data.fullRegionName }}</h1>
      </div>
      <div class="pannel">
        <span>企业名称：</span>
        <h1>{{ data.subjectName }}</h1>
      </div>
      <div class="pannel">
        <span>联系方式：</span>
        <h1>{{ data.createCertPhone }}</h1>
      </div>
      <div v-if="data.createTime" class="pannel">
        <span>开具日期：</span>
        <h1>{{ data.createTime.substring(0, 10) }}</h1>
      </div>
      <div v-if="data.productDate" class="pannel">
        <span>生产日期：</span>
        <h1>{{ data.productDate.substring(0, 10) }}</h1>
      </div>
      <div class="signature">
        <span>生产者盖章或签名:</span>
        <img :src="data.signature" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import cateApi from "@/request/api/hegezhen";
export default {
  props: {
    data: Object,
    basisList: [],
    contentList: [],
  },
  watch: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.hegfez-infor {
  // height: 460px;
  // height: 100%;
  // position: relative;
  .infot {
    padding: 0.28rem;
    box-sizing: border-box;
  }
  .top-code {
    position: relative;
    img {
      width: 100%;
    }
    .code {
      position: absolute;
      top: 50%;
      left: 0.12rem;
      transform: translateY(-50%);
      h1 {
        font-weight: bold;
        font-size: 0.256rem;
        color: #f6ddba;
      }
    }
  }
  .basis-con {
    background-color: #f0f8ee;
    padding: 0.36rem 0.16rem;
    border-radius: 0px 0px 0.24rem 0.24rem;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.224rem;
      h2 {
        font-weight: 800;
        font-size: 0.256rem;
        color: #1a211d;
      }
    }
  }
  ::v-deep .tips-content {
    margin-bottom: 0.3rem;
    .van-checkbox {
      margin-top: 0.12rem;
      span {
        font-weight: 600;
      }
      .van-checkbox__label--disabled {
        color: #000;
      }
      .van-checkbox__icon--disabled .van-icon {
        background-color: #fff;
      }
      .van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
        color: #10d766;
      }
      .van-checkbox__label {
        margin-left: 0.08rem;
      }
    }
  }
  .tips-list {
    margin-top: 0.192rem;
    border-radius: 0.24rem;
    .van-checkbox {
      width: 50%;
    }
  }

  .line {
    height: 0.18rem;
    background: #f6f6f6;
    width: 100%;
  }
  .list {
    padding: 0.28rem;
    box-sizing: border-box;
    .pannel {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 0.192rem;
      h1 {
        padding: 0.08rem 0.28rem;
        background: #f4f8f4;
        flex: 1;
      }
    }
  }
}
.van-checkbox-group-t {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  flex-wrap: wrap;
}
.signature {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.08rem;
  }
}
</style>
