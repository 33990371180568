<template>
  <div style="position: relative; width: 100%">
    <div class="describe-con">
      <div class="rowbox">
        <div @click="customHand(1)" class="row-con">
          <img :src="require(`@/assets/images/zz.png`)" />
          <span class="custom">种植信息</span>
        </div>
        <div @click="customHand(2)" class="row-con">
          <img :src="require(`@/assets/images/sf.png`)" />
          <span class="custom">施肥</span>
        </div>
        <div @click="customHand(3)" class="row-con">
          <img :src="require(`@/assets/images/sh.png`)" />
          <span class="custom">收获</span>
        </div>
        <div
          @click="customHand(4)"
          style="box-shadow: 0px 4px 10px 0px rgba(2, 213, 192, 0.12)"
          class="row-con"
        >
          <img :src="require(`@/assets/images/jk.png`)" />
          <span class="custom">实时监控</span>
        </div>
      </div>
      <div class="rowbox">
        <div @click="customHand(5)" class="row-con">
          <img :src="require(`@/assets/images/dk.png`)" />
          <span class="custom">地块信息</span>
        </div>
        <div @click="customHand(6)" class="row-con yy">
          <img :src="require(`@/assets/images/yy.png`)" />
          <span class="custom">用药记录</span>
        </div>
        <div
          @click="customHand(7)"
          style="box-shadow: 0px 4px 10px 0px rgba(2, 213, 192, 0.12)"
          class="row-con"
        >
          <img :src="require(`@/assets/images/qh.png`)" />

          <span class="custom">气候采集</span>
        </div>
      </div>
      <div class="rowbox">
        <div @click="customHand(8)" class="row-con">
          <img :src="require(`@/assets/images/zm.png`)" />

          <span class="custom">种苗信息</span>
        </div>
        <div @click="customHand(9)" class="row-con">
          <img :src="require(`@/assets/images/zb.png`)" />

          <span class="custom">植保</span>
        </div>
        <div @click="customHand(10)" class="row-con">
          <img :src="require(`@/assets/images/gg.png`)" />
          <span class="custom">灌溉</span>
        </div>
        <div
          @click="customHand(11)"
          style="box-shadow: 0px 4px 10px 0px rgba(2, 213, 192, 0.12)"
          class="row-con"
        >
          <img :src="require(`@/assets/images/dlwz.png`)" />
          <span class="custom">地理位置</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cateApi from "@/request/api/hegezhen";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { ImagePreview } from "vant";
export default {
  props: {
    certificateId: String,
    productBatchId: String,
    unitList: Array,
  },
  watch: {},
  data() {
    return {
      list: [],
      show: true,
      itemData: {},
      audio: new Audio(),
      playShow: false,
      picImgList: [],
      modluIndex: 0,
      form: {},
    };
  },
  mounted() {},
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    async getDatasc() {
      this.show = true;
      try {
        let params = {
          // certificateId: this.certificateId,
          pageNum: 1,
          pageSize: 10,
        };
        if (this.productBatchId) {
          params.productBatchId = this.productBatchId;
        }
        this.$global.loading.show();
        let response = await cateApi.shengchanxinx(params);
        this.$global.loading.hide();
        let list = response.data.list;
        for (let index = 0; index < this.unitList.length; index++) {
          const element = this.unitList[index];
          for (let lindex = 0; lindex < list.length; lindex++) {
            const lelement = list[lindex];
            if (element.value == lelement.weightUnit) {
              this.$set(lelement, "unitName", element.label);
            }
          }
        }
        this.list = list;
        console.log("list", this.list);
      } catch (error) {
        this.$global.loading.hide();
        console.log(error);
      }
    },
    fromHand(item) {
      if (item.operationPic != "") {
        this.picImgList = item.operationPic.split(",");
      }
      if (item.operationVideo) {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
          this.player.log("onPlayerReady", this);
        });
      }
      this.itemData = item;
      this.show = false;
      // let data = {
      //   item,
      // };
      // let params = JSON.stringify(data);
      // this.$router.push({
      //   path: "/fromDateils",
      //   query: { params },
      // });
    },

    //点击播放音频
    async playHand() {
      await this.$nextTick();
      this.$refs["audio"].play();
      this.playShow = true;
    },
    overAudio() {
      this.playShow = false;
    },
    //点击暂停
    suspendHand() {
      this.$refs["audio"].pause();
      this.playShow = false;
    },
    imgagePre() {
      console.log(this.picImgList);
      ImagePreview(this.picImgList);
    },
    backChange() {
      this.detailShow = true;
    },
    async customHand(idx) {
      if (idx == 9) {
        return;
      }
      let params = {
        modluIndex: idx,
        batchId: this.productBatchId,
      };
      params = JSON.stringify(params);
      this.$router.push({
        path: `/produce`,
        query: { params },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.describe-con {
  padding: 0.08rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  // background: #f0fcf3;
  .rowbox {
    border-radius: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .row-con {
      background: #fff;
      width: 95px;
      height: 120px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 0.012rem 0 rgba(218, 214, 214, 0.2);
      margin-bottom: 10px;
      border-radius: 10px;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 20px;
      }
      .custom {
        font-weight: bold;
        font-size: 13px;
        color: #000000;
        line-height: 15px;
      }
    }
    .yy {
      height: 250px;
      background: linear-gradient(360deg, #e5fcfa 0%, #c9fef0 100%);
      border: 1px solid #ffffff;
    }
  }
}
</style>
